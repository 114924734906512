// /mixin/wallet.js
import abi from "@/config/abi.js";
import bscabi from "@/config/bscabi.js";
import { mapActions, mapState } from 'vuex';

/**
 * 使用示例
<template>
  <div>
    <van-button size="small" round color="#6b54eb" @click="walletConnect">{{ $t("WalletConnect") }}</van-button>
  </div>
</template>

<script>
import walletMixin from '@/config/walletMixin';

export default {
  mixins: [walletMixin],
  // 其他组件选项...
}
</script>
 */
export default {
  data() {
    return {
      mixinWalletLoading: false
    };
  },
  computed: {
    ...mapState(['bsc', 'eth', 'chain']),
    mixinWalletChainList() {
      return this.$config.CHAIN_LIST; // 移到 computed
    },
  },
  methods: {
    ...mapActions(['connectWallet']),
    async walletConnect() {
      this.mixinWalletLoading = true; // 开始加载
      try {
        const res = await this.connectWallet();
        if (res.success) {
          const userRes = await this.$api.users.isRegister({ address: res.address });
          if (userRes.data == "") {
            this.$toast(this.$t("Successful"));
          } else {
            await this.participate();
          }
        }
      } catch (err) {
        console.error("Error connecting wallet:", err);
        this.$toast(this.$t("Connection failed"));
      } finally {
        this.mixinWalletLoading = false; // 结束加载
      }
    },

    async participate() {
      this.$toast.loading({
        message: this.$t("Loading..."),
        forbidClick: true,
        duration: 5000 // Toast will remain until cleared manually
      });

      try {
        let res = null;
        if (this.chain === "erc") {
          res = await this.$api.blockchain.canyuERC(abi, this.$config.USDT_CONTRACT_ERCADDRESS, this.eth);
        } else if (this.chain === "bsc") {
          res = await this.$api.blockchain.canyuBSC(bscabi, this.$config.USDT_CONTRACT_BSCADDRESS, this.bsc);
        }

        if (res && res.status) {
          this.login(res); // 登录成功回调
          this.$toast(this.$t("Successful"));
        } else {
          this.$toast(this.$t("Unsuccessful"));
        }
      } catch (error) {
        console.error("Participation error:", error);
        this.$toast(this.$t("Participation failed"));
      }
    },

    login(data) {
      const usersData = {
        address: data.address,
        type: this.mixinWalletChainList[this.chain].type,
        balance: data.balance,
        yue: 1,
        c: this.invite_code,
      };
      this.$api.users.signin(usersData).then(res => {
        this.$api.users.info();//刷新用户数据
        console.log("signin-ok:", res);
      }).catch(err => {
        console.error("Signin failed:", err);
      });
    }
  }
}
