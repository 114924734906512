<template>
  <div>
    <div class="head">
      <van-row>
        <van-col span="4"> </van-col>
        <van-col span="12">
          <div class="head-chain">
            <img :src="activeChain.img" alt="" class="head-chain__icon" @click="showPopover = !showPopover" />
            <van-popover :show="showPopover" trigger="click" placement="bottom">
              <!-- 选择网络交互框 -->
              <div class="head-chain-btn">
                <van-row>
                  <van-col span="24">{{ $t("Select Network") }}</van-col>
                </van-row>
                <!-- 四链选择 -->
                <van-row class="head-chain-btn__chain-list" align="center">
                  <van-col span="24" v-for="(item, index) in chainList"
                    :class="{ 'head-chain-btn__item': index !== 'bsc' }" @click="changeChainNet(index)">
                    <van-row>
                      <van-col span="18">
                        <div class="head-chain-btn__item-content">
                          <img :src="item.img" alt="" class="head-chain-btn__item-icon" />
                          <span class="head-chain-btn__item-title">{{ item.title }}</span>
                        </div>
                      </van-col>
                      <van-col span="6">
                        <van-icon name="star" :color="index == activeChain.id ? 'yellow' : ''" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </div>
              <template #reference>
                <van-icon name="arrow-down" size="15" class="head-chain-btn__arrow-icon"
                  @click="showPopover = !showPopover"></van-icon>
              </template>
            </van-popover>
            <div class="head-chain__button-container">
              <van-button size="small" round color="#6b54eb" @click="walletConnect">
                {{ $t("WalletConnect ") }}
              </van-button>
            </div>
          </div>
        </van-col>

        <van-col span="8" class="head-lang">
          <div class="head-lang__container">
            <div class="head-lang__bubble">
              <van-icon name="chat-o" @click="chatme(kefu)" style="color: #ffffff; font-size: 22px" dot></van-icon>
              <img :src="activeLang.img" alt="" class="head-lang__flag" @click="langShow = !langShow" />
              <van-popover :show="langShow" trigger="click" placement="bottom-end">
                <div class="head-lang__popover">
                  <van-row>
                    <van-col span="24"> lang </van-col>
                  </van-row>
                  <van-row class="head-lang__lang-list" align="center">
                    <van-col span="24" v-for="(item, index) in langList"
                      :class="{ 'head-lang__lang-item': index !== 'en' }" @click="changeLang(index)">
                      <van-row>
                        <van-col span="18">
                          <div class="head-lang__lang-item-content">
                            <img :src="item.img" alt="" class="head-lang__lang-item-icon" />
                            <span class="head-lang__lang-item-title">{{ item.title }}</span>
                          </div>
                        </van-col>
                        <van-col span="6">
                          <van-icon name="star" :color="index == activeLang.id ? 'yellow' : ''" />
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </div>
                <template #reference>
                  <van-icon name="arrow-down" size="15" class="head-lang__arrow-icon"
                    @click="langShow = !langShow"></van-icon>
                </template>
              </van-popover>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
  <div v-if="ifshow" class="mydunhuans">
    <!-- <div class="img_1"></div> -->
    <div>
      <span>{{ $t("Please connect your wallet first") }}</span>
    </div>
  </div>
  <router-view />
  <div class="footer">
    <van-row class="footer-container" style="margin-top: 10px">
      <van-col span="6" class="footer__icon" @click="$util.router.goto('/')">
        <img :src="getHomeImg" alt="" class="footer__image" />
      </van-col>
      <van-col span="6" class="footer__icon hide" @click="$util.router.goto('/change')">
        <img :src="getChangeImg" alt="" class="footer__image" />
      </van-col>
      <van-col span="6" class="footer__icon" @click="$util.router.goto('/service')">
        <img :src="getServiceImg" alt="" class="footer__image" />
      </van-col>
      <van-col span="6" class="footer__icon" @click="$util.router.goto('my')">
        <img :src="getMyImg" alt="" class="footer__image" />
      </van-col>
    </van-row>
    <van-row class="footer-container footer__text">
      <van-col span="6" class="footer__text-item" @click="$util.router.goto('/')">{{ $t("Home") }}</van-col>
      <van-col span="6" class="footer__text-item hide" @click="$util.router.goto('/change')">{{ $t("Exchange")
        }}</van-col>
      <van-col span="6" class="footer__text-item" @click="$util.router.goto('/service')">{{ $t("Service") }}</van-col>
      <van-col span="6" class="footer__text-item" @click="$util.router.goto('/my')">{{ $t("Center") }}</van-col>
    </van-row>
  </div>
</template>

<script>
import walletMixin from '@/mixin/wallet';
import { mapState, mapActions } from "vuex";

export default {
  mixins: [walletMixin],
  data() {
    return {
      baseURL: this.$config.BASE_URL, // 使用配置的 baseURL
      showPopover: false,
      active: 1,
      web3: "",
      tronweb: "",
      ifshow: false,
      chainList: this.$config.CHAIN_LIST,

      langShow: false,
      langList: this.$config.LANG_LIST,
    };
  },
  computed: {
    ...mapState(['address', 'chain', 'lang', 'invite_code', 'kefu']),
    getHomeImg() {
      return this.$route.path == "/" ? require("@/assets/image/home_select.png") : require("@/assets/image/home.png");
    },
    getChangeImg() {
      return this.$route.path == "/change" ? require("@/assets/image/change_select.png") : require("@/assets/image/change.png");
    },
    getServiceImg() {
      return this.$route.path == "/service" ? require("@/assets/image/service_select.png") : require("@/assets/image/service.png");
    },
    getMyImg() {
      return this.$route.path == "/my" ? require("@/assets/image/my_select.png") : require("@/assets/image/my.png");
    },
    activeLang: function () {
      let item = { img: '' };
      try {
        item = this.langList[this.lang];
      } catch (error) {
        let firstKey = Object.keys(this.langList)[0];
        item = this.langList[firstKey];
        this.updateLang(firstKey)
      }
      return item;
    },
    activeChain: function () {
      let item = { img: '' };
      try {
        item = this.chainList[this.chain];
      } catch (error) {
        let firstKey = Object.keys(this.chainList)[0];
        item = this.chainList[firstKey];
        this.updateChain(firstKey)
      }
      return item;
    }
  },
  watch() { },
  methods: {
    ...mapActions(["connectWallet", "updateChain", "updateLang", "updateState"]),
    
    /**
     * 打开客服
     */
    chatme: function (url) {
      const targetUrl = url ?? this.kefu;
      if (this.$util.tools.isAbsoluteURL(targetUrl)) {
        this.$util.router.jump(targetUrl);
      } else {
        this.$util.router.goto(targetUrl);
      }
    },
    changeChainNet: function (index) {
      this.updateChain(index)
      this.showPopover = !this.showPopover;
    },
    changeLang: function (index) {
      this.updateLang(index)
      this.$i18n.locale = index; //设置国际化
      this.langShow = !this.langShow;
    },

  },
  mounted() {
    console.log('=========app-mounted=========')
    //获取基础信息
    this.$api.index.init();
    //启动APP链接钱包，并尝试更新用户信息
    this.connectWallet().then(res => {
      //console.log('链接钱包:', res)
      if (res.success) {
        this.$api.users.info();//获取并设置用户信息
      }
    })

    if (this.$getters.address) {
      this.ifshow = false;
    }

    //邀请码
    let url = window.location.href;
    let cs = url.split("=")[1] ?? '';
    this.updateState({ key: 'invite_code', value: cs })
  },
};
</script>

<style>
@import "./assets/main.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 0.8125rem;
  margin: 0 auto;
  max-width: var(--max-screen-width) !important;
}

.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: rgba(33, 34, 68, 1);
}

.head-chain {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 60px;

}

.head-chain-btn {
  background-color: rgb(44, 44, 83);
  color: rgb(112, 128, 179);
  padding: 20px 15px;
  box-sizing: border-box;
  font-size: 0.75rem;
  width: 200px;
}


.head-chain__icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.head-chain-btn__chain-list {
  margin-top: 20px;
}

.head-chain-btn__item {
  margin-top: 15px;
}

.head-chain-btn__item-content {
  display: flex;
  align-items: center;
}

.head-chain-btn__item-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.head-chain-btn__item-title {
  margin-left: 10px;
}

.head-chain-btn__arrow-icon {
  margin-left: 10px;
  color: #ffffff;
}

.head-chain__button-container {
  margin-top: -4px;
  margin-right: 10px;
  margin-left: 20px;
}

.head-lang {
  display: flex;
  justify-content: center;
  align-items: center;
}

.head-lang__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
}

.head-lang__bubble {
  width: 80px;
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  /* 改为rgba格式 */
  padding: 10px 20px;
  border-radius: 15px;
}

.head-lang__chat-icon {
  color: #ffffff;
  font-size: 22px;
}

.head-lang__flag {
  width: 22px;
  height: 22px;
  margin-left: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.head-lang__popover {
  background-color: rgb(44, 44, 83);
  color: rgb(112, 128, 179);
  padding: 20px 15px;
  box-sizing: border-box;
  font-size: 0.75rem;
  width: 200px;
}

.head-lang__lang-list {
  margin-top: 20px;
}

.head-lang__lang-item {
  margin-top: 15px;
}

.head-lang__lang-item-content {
  display: flex;
  align-items: center;
}

.head-lang__lang-item-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.head-lang__lang-item-title {
  margin-left: 10px;
}

.head-lang__arrow-icon {
  margin-left: 10px;
  color: #ffffff;
}


.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #212244;
  max-width: var(--max-screen-width) !important;
}

.footer-container {
  display: flex;
  justify-content: space-between;
}

.footer__icon {
  font-size: 15px;
  text-align: center;
}

.footer__image {
  width: 30px;
  height: 30px;
}

.footer__text {
  margin-top: 10px;
  color: #a9abfc;
}

.footer__text-item {
  font-size: 12px;
  text-align: center;
}

/* .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(33, 34, 68, 1);
} */

.mydunhuans {
  z-index: 999;
  position: fixed;
  /* 居中 */
  top: 50%;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 10%;
  background-color: #fff;
  border-radius: 20px;
  background-color: rgba(34, 32, 32, 0.8);
  justify-content: center;
  align-content: center;
  display: flex;

  overflow-y: auto;
  -ms-overflow-style: none;
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0);
  --tw-gradient-from: #0993ec;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
  --tw-gradient-to: #f338c3;
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
  /* 字居中 */
}

.mydunhuans div {
  font-size: 24px;
  /* justify-content: center; */
  /* align-content: center; */
  /* display: flex; */
  margin-top: 20px;
}
</style>
