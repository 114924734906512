// store/index.js
import { createStore } from 'vuex'
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters'
import createPersistedState from 'vuex-persistedstate';


export default createStore({
  state: {
    address: '',
    invite_code: '',
    kefu: '',
    bsc: '',
    eth: '',
    chain: 'bsc', // 默认链，确保存在于 config.CHAIN_LIST 中
    lang: 'en', // 默认语言，确保存在于 config.LANG_LIST 中
    users: { balance: '-', leiji: '-' },
    token: ''
  },
  getters,
  mutations, // 同步方法
  actions, // 异步/同步方法
  modules: {},
  plugins: [
    // 默认将大部分数据存储在 sessionStorage 中
    createPersistedState({
      key: 'session-storage',
      //paths: ['address', 'invite_code', 'kefu', 'bsc', 'eth', 'chain', 'users', 'token'], // 只持久化这些字段
      storage: window.sessionStorage // 使用 sessionStorage
    }),
    // lang 使用 localStorage
    createPersistedState({
      key: 'local-storage',
      paths: ['lang', 'token'], // 只持久化 'lang', 'token' 字段
      storage: window.localStorage // 使用 localStorage
    }),
  ],
})
