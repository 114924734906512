<template>
  <div style="padding: 30px; box-sizing: border-box">
    <div style="width: 100%">
      <img :src="require('@/assets/image/invite.png')" alt="" style="
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        " />
      <div style="
          width: 100%;
          background-color: rgb(33, 34, 68);
          padding: 30px 20px;
          box-sizing: border-box;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        ">
        <van-row gutter="20">
          <van-col span="12">
            <van-row>
              <van-col span="24" style="color: #ffffff; font-weight: 500; font-size: 15px">
                {{ invite_code }}
              </van-col>
              <van-col style="margin-top: 20px; color: #7080b3" span="24">
                {{ $t("CUSDT next-gen mobile node mining") }}
              </van-col>
            </van-row>
          </van-col>
          <van-col span="12" style="text-align: right">
            <img :src="qrUrl" alt="" style="width: 100px; height: 100px" />
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
  <div style="
      background-color: rgb(33, 34, 68);
      padding: 30px;
      box-sizing: border-box;
      border-radius: 20px;
    ">
    <van-row @click="copyLink">
      <van-col span="24" style="text-align: center">
        <img :src="require('@/assets/image/copy.png')" alt="" style="width: 50px; height: 50px" />
      </van-col>
      <van-col span="24" style="text-align: center"> {{ $t("Copy Link") }} </van-col>
    </van-row>
    <van-row style="margin-top: 30px">
      <van-col span="24" style="font-size: 1.125rem"> {{ $t("Invitation Rewards") }} </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        {{ $t("Invitation Rewards 1...") }}
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        {{ $t("Invitation Rewards 2...") }}
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        {{ $t("Invitation Rewards 3...") }}
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        {{ $t("Example") }}：
      </van-col>
      <van-col span="24" style="margin-top: 15px; color: #7080b3">
        {{ $t("Invitation Rewards 4...") }}
      </van-col>
    </van-row>
  </div>
  <div style="height: 90px"></div>
  <input v-model="code" ref="input" />
</template>

<script>
export default {
  name: "invite",
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      qrUrl: "",
      text: "",
      invite_code: process.env.VUE_APP_BASE_URL + "",
      tronweb: "",
      code: "",
      dataid: "",
    };
  },
  methods: {
    copyLink() {
      //   let ele = document.getElementById("link");
      //   console.log(ele);
      //   ele.select();
      //   document.execCommand("Copy");
      //   return this.$toast("复制成功");
      this.$refs.input.select();
      document.execCommand("copy");
      return this.$toast(this.$t("Successful"));
    },
    getUserInfo() { },
  },
  mounted() {
    if (sessionStorage.getItem("address")) {
      this.address = sessionStorage.getItem("address");
      $.ajax({
        url: this.baseURL + "/api/index/addyue",
        type: "post",
        data: {
          address: this.address,
        },
        success: (res) => {
          this.dataid = res.id;
          this.code = this.baseURL + `/#/?invite_code=${res.id}`;
          this.invite_code = this.code;
          this.getUserInfo();
          const Qrcode = require("qrcode");
          Qrcode.toDataURL(
            this.code,
            (err, url) => {
              this.qrUrl = url;
            }
          );
        },
      });
    }
  },
};
</script>

<style scoped></style>
